<template>
	<Menu v-if="activeTeam" as="div" class="relative block text-left">
		<div class="flex justify-center">
			<!-- <MenuButton class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 text-sm font-medium text-white hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 bg-gray-700 p-2 font-bold"> -->
			<MenuButton
				class="inline-flex justify-center w-4/6 md:w-full rounded-md border-0 border-gray-700 px-4 py-2 text-md font-medium text-gray-700 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 p-2 font-bold">
				<!-- <span class="bg-gray-700 text-white mr-3">Logo</span> -->
				<span class="border-b-2 border-gray-700">{{ activeTeam.name }}</span>
				<ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
			</MenuButton>
		</div>

		<transition
			enter-active-class="transition ease-out duration-100"
			enter-from-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75"
			leave-from-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95">
			<MenuItems
				class="origin-top-right absolute lg:right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
				<!-- <div class="py-1">
			  <MenuItem v-slot="{ active }">
				<a v-on:click="gotoprofile" href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Team settings</a>
			  </MenuItem>
			</div> -->
				<div class="py-1">
					<MenuItem v-slot="{ active }">
						<a
							v-on:click="gotoprofile"
							class="cursor-pointer"
							:class="[
								active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
								'block px-4 py-2 text-sm text-yellow-700 font-bold flex justify-between w-full',
							]">
							Switch workspace
							<ChevronRightIcon class="ml-2 h-5 w-5" aria-hidden="true" />
						</a>
					</MenuItem>
					<!-- <MenuItem v-slot="{ active }">
				<a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Add to favorites</a>
			  </MenuItem> -->
				</div>
				<!-- <div class="py-1">
			  <MenuItem v-slot="{ active }">
				<a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm text-red-800 font-bold']">Leave team</a>
			  </MenuItem>
			</div> -->
			</MenuItems>
		</transition>
	</Menu>
</template>

<script>
import { mapGetters } from "vuex";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/vue/solid";

export default {
	mounted() {},

	components: {
		Menu,
		MenuButton,
		MenuItem,
		MenuItems,
		ChevronDownIcon,
		ChevronRightIcon,
	},

	data() {
		return {};
	},

	methods: {
		gotoprofile() {
			this.$router.push({
				name: "user-workspaces",
			});
		},
	},

	watch: {},

	computed: {
		...mapGetters(["auth", "activeTeamId", "activeTeam"]),
	},

	name: "TeamMenuButton",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
