<template>
	<TopNav />

	<div v-if="loadingTeam || loadingUser" class="flex flex-col items-center justify-center min-h-screen">
		<spinner :size="60" color="black" :animation-duration="1000" />
		<div v-if="loadingTeam" class="mt-5 text-gray-800 tracking-widest">Loading workspace</div>
	</div>

	<div v-if="loadingTeam == false" class="flex flex-row flex-wrap py-4">
		<aside :class="[showMainSidebar || showUserSidebar ? 'w-full md:w-1/4 lg:w-1/5 px-2' : 'hidden']">
			<div class="sticky top-0 w-full">
				<!-- navigation -->
				<MainSidebar v-if="showMainSidebar && !showUserSidebar" />
				<UserProfileSidebar v-if="showUserSidebar" />
			</div>
		</aside>

		<!-- Shown on mobile -->
		<div class="md:hidden w-full">
			<TeamMenuButton v-if="showMainSidebar" />
		</div>

		<main
			role="main"
			:class="[showMainSidebar || showUserSidebar ? 'w-full md:w-3/4 lg:w-4/5 pt-1 px-2' : 'w-full']">
			<div v-if="appLoading" class="flex items-center justify-center min-h-screen">
				<spinner :size="60" :color="'#FF87AB'" :animation-duration="2000" />
			</div>

			<!-- route outlet -->
			<!-- component matched by the route will render here -->
			<div class="pl-5">
				<router-view v-slot="{ Component }">
					<keep-alive>
						<component v-if="$route.name == 'workspace-prospect-search'" :is="Component" />
					</keep-alive>
					<component v-if="$route.name != 'workspace-prospect-search'" :is="Component" />
				</router-view>
			</div>
		</main>
	</div>
</template>

<script>
import TopNav from "./components/TopNav.vue";
import MainSidebar from "./components/MainSidebar.vue";
import UserProfileSidebar from "./components/UserProfileSidebar.vue";
import TeamMenuButton from "@/components/TeamMenuButton";
import { mapGetters } from "vuex";
import { ScalingSquaresSpinner } from "epic-spinners";

const userSidebarPaths = ["/user"];
let shouldShowUserSidebar = function (path) {
	for (let i in userSidebarPaths) {
		if (path.includes(userSidebarPaths[i])) {
			return true;
		}
	}
	return false;
};

const mainSidebarPaths = ["/workspace"];
let shouldShowMainSidebar = function (path) {
	for (let i in mainSidebarPaths) {
		if (path.includes(mainSidebarPaths[i])) {
			return true;
		}
	}
	return false;
};

let findUserTeamPermission = function (user, team) {
	for (var i in user.teams) {
		if (user.teams[i].team_id == team.team_id) {
			return user.teams[i].permission;
		}
	}
};

export default {
	name: "App",

	created() {
		this.loadingUser = true;
		this.getUser();
		// this.ensureTeamLoaded()
	},
	mounted() {
		// this.loadingUser = true;
		// this.getUser();
		// this.ensureTeamLoaded()
	},

	data() {
		return {
			loadingUser: false,
			loadingTeam: false,
			showUserSidebar: shouldShowUserSidebar(this.$route.path),
			showMainSidebar: shouldShowMainSidebar(this.$route.path),
		};
	},

	watch: {
		$route() {
			this.showUserSidebar = shouldShowUserSidebar(this.$route.path);
			this.showMainSidebar = shouldShowMainSidebar(this.$route.path);
			this.ensureTeamLoaded();
		},
	},

	methods: {
		async ensureTeamLoaded() {
			// console.log(this.$route.params)
			if ("teamId" in this.$route.params) {
				if (this.$route.params.teamId != null && this.activeTeamId != this.$route.params.teamId) {
					this.loadingTeam = true;
					this.$store.commit("setActiveTeam", { teamId: null, team: null });
					this.getTeam(this.$route.params.teamId);
				}
			}
		},

		async getUser() {
			try {
				// Must wait for the api object to be created
				while (this.$api == null) {
					await new Promise((r) => setTimeout(r, 1000));
				}

				let response = await this.$api.resource("users").fetchOne(this.auth.user.email);
				this.$store.commit("setActiveUser", { user: response.data });
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
			this.loadingUser = false;
		},

		async getTeam(teamId) {
			// console.log(teamId)
			try {
				await new Promise((r) => setTimeout(r, 1000));
				let response = await this.$api.resource("teams").fetchOne(teamId);
				let user_team_permission = findUserTeamPermission(this.activeUser, response.data);
				// The user's team list might be out of date if they just created a new team
				// So update the user object if needed
				if (user_team_permission == null) {
					await this.getUser();
					user_team_permission = findUserTeamPermission(this.activeUser, response.data);
				}
				this.$store.commit("setActiveTeam", {
					teamId: response.data.team_id,
					team: response.data,
					permission: user_team_permission,
				});
			} catch (e) {
				console.log(`the error is here: ${e}`);
				this.$router.push({
					name: "user-workspaces",
				});
			}
			this.loadingTeam = false;
		},
	},

	components: {
		TopNav,
		MainSidebar,
		UserProfileSidebar,
		TeamMenuButton,
		spinner: ScalingSquaresSpinner,
	},

	computed: {
		...mapGetters(["auth", "appLoading", "activeTeamId", "activeUser"]),
	},
};
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/*text-align: center;*/
	/*color: #2c3e50;*/
}
</style>
