import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

//---------- routes ---------------------------

const routes = [
	{ path: "/", redirect: { name: "user-home" } },

	{
		path: "/user/home",
		name: "user-home",
		redirect: { name: "user-workspaces" },
		// component: lazyLoad('HomeDashboard'),
		// meta: {
		//   title: 'Foresight SI :: Home'
		// }
	},

	{
		path: "/user/workspaces",
		name: "user-workspaces",
		props: true,
		component: lazyLoad("UserTeams"),
		meta: {
			title: "Foresight SI :: Workspaces",
		},
	},

	// { path: '/teams',
	//   name: 'teams',
	//   component: lazyLoad('Teams'),
	// },

	{
		path: "/workspace/:teamId/dashboard",
		name: "workspace-dashboard",
		redirect: { name: "workspace-projects" },
		// props: true,
		// component: lazyLoad('TeamDashboard'),
		// meta: {
		//   title: 'Foresight SI :: Team dashboard'
		// }
	},
	{
		path: "/workspace/:teamId/settings",
		name: "workspace-settings",
		props: true,
		component: lazyLoad("TeamSettingsDashboard"),
		meta: {
			title: "Foresight SI :: Workspace settings",
		},
	},
	{
		path: "/workspace/:teamId/projects",
		name: "workspace-projects",
		props: true,
		component: lazyLoad("TeamProjects"),
		meta: {
			title: "Foresight SI :: Workspace projects",
		},
	},

	{
		path: "/workspace/:teamId/prospect-search",
		name: "workspace-prospect-search",
		props: true,
		component: lazyLoad("ProspectOmniSearchV2"),
		meta: {
			title: "Foresight SI :: Prospect search",
		},
	},
	{
		path: "/workspace/:teamId/prospect-search/:resultId",
		name: "view-prospect-search-result",
		props: true,
		component: lazyLoad("ViewProspectSearchResult"),
		meta: {
			title: "Foresight SI :: View result",
		},
	},

	{
		path: "/workspace/:teamId/prospects/:projectId",
		name: "prospects-project",
		props: true,
		component: lazyLoad("ProspectProject"),
		meta: {
			title: "Foresight SI :: Prospect project",
		},
	},
	{
		path: "/workspace/:teamId/prospects/:projectId/lists/:listId",
		name: "prospects-project-prospect-list",
		props: true,
		component: lazyLoad("ProspectProjectProspectList"),
		meta: {
			title: "Foresight SI :: Prospect list",
		},
	},
	{
		path: "/workspace/:teamId/prospects/:projectId/lists/:listId/:prospectId",
		name: "view-project-list-prospect",
		props: true,
		component: lazyLoad("ViewProjectListProspect"),
		meta: {
			title: "Foresight SI :: View prospect",
		},
	},

	{
		path: "/workspace/:teamId/company-analysis/:projectId",
		name: "company-analysis-project",
		props: true,
		component: lazyLoad("CompanyAnalysisProject"),
		meta: {
			title: "Foresight SI :: Company Analysis project",
		},
	},
	{
		path: "/workspace/:teamId/company-analysis/:projectId/companies/:companyId",
		name: "company-analysis-project-company",
		props: true,
		component: lazyLoad("ViewAnalysedCompany"),
		meta: {
			title: "Foresight SI :: View analysed company",
		},
	},
	{
		path: "/workspace/:teamId/domain-analysis",
		name: "workspace-domain-analysis",
		props: true,
		component: lazyLoad("DomainAnalysis"),
		meta: {
			title: "Foresight SI :: Domain analysis",
		},
	},

	{
		path: "/workspace/:teamId/digital-reference-check",
		name: "workspace-digital-reference-check",
		props: true,
		component: lazyLoad("DigitalReferenceCheckView"),
		meta: {
			title: "Foresight SI :: Digital Reference Check",
		},
	},

	// { path: '/team/:teamId/:projectType/:resourceId/manage',
	//   name: 'projectManageProspects',
	//   props: true,
	//   component: lazyLoad('ProjectManageProspects'),
	// },

	// { path: '/login',
	//   name: 'login',
	//   component: lazyLoad('Login'),
	//   meta: {
	//     title: 'Foresight SI :: Login'
	//   }
	// },
	{
		path: "/register",
		name: "register",
		component: lazyLoad("Register"),
		meta: {
			title: "Foresight SI :: Register",
		},
	},

	// match everything and put it under `$route.params.pathMatch`
	{
		path: "/:catchAll(.*)",
		name: "NotFound",
		component: lazyLoad("HomeDashboard"),
		meta: {
			title: "Foresight SI :: Home",
		},
	},
];

//------- rotuer ---------------------------

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.name !== "login" && !store.getters.isAuthenticated) {
		next({ name: "login" });
	} else {
		if (to.name !== "register" && !store.getters.isRegistered) {
			next({ name: "register" });
		} else {
			next();
		}
	}
});

/**
 * Lazy load view component with route-level code splitting,
 * generates a separate chunk for the route
 *
 * @param {string} view
 * @returns
 */
function lazyLoad(view) {
	return () => import(/* webpackChunkName: "[request]" */ `@/views/${view}.vue`);
}

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
	// This goes through the matched routes from last to first, finding the closest route with a title.
	// e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
	// `/nested`'s will be chosen.
	const nearestWithTitle = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.title);

	// Find the nearest route element with meta tags.
	const nearestWithMeta = to.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	const previousNearestWithMeta = from.matched
		.slice()
		.reverse()
		.find((r) => r.meta && r.meta.metaTags);

	// If a route with a title was found, set the document (page) title to that value.
	if (nearestWithTitle) {
		document.title = nearestWithTitle.meta.title;
	} else if (previousNearestWithMeta) {
		document.title = previousNearestWithMeta.meta.title;
	}

	// Remove any stale meta tags from the document using the key attribute we set below.
	Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode.removeChild(el));

	// Skip rendering meta tags if there are none.
	if (!nearestWithMeta) return next();

	// Turn the meta tag definitions into actual elements in the head.
	nearestWithMeta.meta.metaTags
		.map((tagDef) => {
			const tag = document.createElement("meta");

			Object.keys(tagDef).forEach((key) => {
				tag.setAttribute(key, tagDef[key]);
			});

			// We use this to track which meta tags we create so we don't interfere with other ones.
			tag.setAttribute("data-vue-router-controlled", "");

			return tag;
		})
		// Add the meta tags to the document head.
		.forEach((tag) => document.head.appendChild(tag));

	next();
});

export default router;
