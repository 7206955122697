import { createStore } from "vuex";
import Auth from "@/services/Auth";
import { reactive } from "vue";
import Api from "@/services/ApiProvider";

const api = new Api();

const auth = reactive(new Auth());
// console.log(`Auth initialized:`);
// console.dir(auth);

export default createStore({
	//----------- state ---------------------------

	state: {
		auth: {
			// token: localStorage.getItem('userToken') || '',
			token: null,
			user: {},
			roles: {},
		},

		appLoading: false,

		activeTeamId: null,
		activeTeamPermission: null,
		activeTeam: null,

		activeUser: null,
	},

	//-------------- getters --------------------------

	getters: {
		auth: (state) => {
			return state.auth;
		},

		activeTeamId: (state) => {
			return state.activeTeamId;
		},
		activeTeamPermission: (state) => {
			return state.activeTeamPermission;
		},
		activeTeam: (state) => {
			return state.activeTeam;
		},

		activeUser: (state) => {
			return state.activeUser;
		},

		isAuthenticated: (state, getters) => {
			let isAuth = false;

			if (getters.auth.token) {
				// console.log('Token exists');
				isAuth = true;
			} else {
				console.log("No auth token");
			}

			return isAuth;
		},

		isRegistered: (state) => {
			return state.auth.user.isRegistered;
		},

		appLoading: (state) => {
			return state.appLoading;
		},
	},

	//----------- mutations --------------------------

	mutations: {
		authSuccess(state, { token, user, roles }) {
			state.auth.token = token;
			state.auth.user = user;
			state.auth.roles = roles;
		},

		userRegisteredStatus(state, isRegistered) {
			state.auth.user.isRegistered = isRegistered;
		},

		appLoading(state, isLoading) {
			state.appLoading = isLoading;
		},

		setActiveTeam(state, { teamId, team, permission }) {
			state.activeTeamId = teamId;
			state.activeTeam = team;
			state.activeTeamPermission = permission;
		},

		setActiveUser(state, { user }) {
			state.activeUser = user;
		},
	},

	//---------------- actions ----------------------

	actions: {
		async authenticate({ commit, dispatch }) {
			await auth.init();
			//localStorage.setItem('userToken', auth.auth.token);
			commit("authSuccess", { ...auth.auth });

			dispatch("scheduleRefreshAuthToken");

			try {
				await api.resource("users").fetchOne(auth.auth.user.email);
				commit("userRegisteredStatus", true);
			} catch (error) {
				commit("userRegisteredStatus", false);
			}
		},

		scheduleRefreshAuthToken({ state }) {
			// refresh every 3min with timeout 4min
			let timeUntilRefresh = 180;
			let timeout = 240;

			setInterval(async () => {
				await auth.refreshToken(timeout);
				state.auth.token = auth.auth.token;
			}, timeUntilRefresh * 1000);
		},

		logout() {
			auth.logout();
		},
	},

	//------------- modules -------------------------

	modules: {},
});
