<template>
	<!-- Filler div -->
	<div
		class="sidebar text-gray-600 md:w-1/4 lg:w-1/5 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out"></div>
	<!-- Fixed nav div -->
	<div
		class="md:w-1/4 lg:w-1/5 space-y-6 absolute inset-y-0 left-0 transform -translate-x-full transition duration-200 ease-in-out md:fixed md:translate-x-0 border-r">
		<div class="mt-14 pt-7 px-2">
			<TeamMenuButton />
		</div>

		<nav class="border-t">
			<div v-for="item in navigation" :key="item.name">
				<div v-if="item.show">
					<div v-if="item.divider" class="border-t"></div>
					<router-link
						:to="item.href"
						:class="[
							item.current ? 'bg-gray-500 text-white' : '',
							'block py-2.5 px-4 mx-2 rounded-md transition duration-200 hover:bg-gray-700 hover:text-white my-2',
						]"
						:aria-current="item.current ? 'page' : undefined">
						<div class="flex items-center">
							<span v-html="item.icon"></span>
							<div class="ml-2">{{ item.name }}</div>
						</div>
					</router-link>
				</div>
			</div>
		</nav>
	</div>
</template>

<script>
let dash_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
					</svg>`;
let projects_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
						</svg>`;
let search_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
						</svg>`;
let settings_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
						  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
						</svg>`;
let chart_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
						</svg>`;
let calculator_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
					  <path stroke-linecap="round" stroke-linejoin="round" d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
					</svg>`;

import { mapGetters } from "vuex";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import TeamMenuButton from "@/components/TeamMenuButton";

let make_navigation = function (self) {
	if (!self.$route.path.includes("/workspace/")) {
		return [];
	}
	let team_dashboard_path = "/workspace/" + self.activeTeamId + "/dashboard";
	let team_projects_path = "/workspace/" + self.activeTeamId + "/projects";
	let team_prospect_search_path = "/workspace/" + self.activeTeamId + "/prospect-search";
	let team_domain_analysis_path = "/workspace/" + self.activeTeamId + "/domain-analysis";
	let team_digital_reference_check_path = "/workspace/" + self.activeTeamId + "/digital-reference-check";
	let team_prospect_settings_path = "/workspace/" + self.activeTeamId + "/settings";
	const navigation = [
		// { name: 'Team dashboard', href: team_dashboard_path, current: self.$route.path == team_dashboard_path, icon: dash_icon, divider: false, show: true },
		{
			name: "Projects",
			href: team_projects_path,
			current: self.$route.path == team_projects_path,
			icon: projects_icon,
			divider: false,
			show: true,
		},
		{
			name: "Company search",
			href: team_prospect_search_path,
			current: self.$route.path == team_prospect_search_path,
			icon: search_icon,
			divider: true,
			show: ["ADMIN", "MANAGE", "WRITE", "READ"].includes(self.activeTeamPermission),
		},
		{
			name: "Quick Company analysis",
			href: team_domain_analysis_path,
			current: self.$route.path == team_domain_analysis_path,
			icon: chart_icon,
			divider: true,
			show: ["ADMIN", "MANAGE", "WRITE", "READ"].includes(self.activeTeamPermission),
		},
		{
			name: "Digital Reference Check",
			href: team_digital_reference_check_path,
			current: self.$route.path == team_digital_reference_check_path,
			icon: calculator_icon,
			divider: true,
			show: ["ADMIN", "MANAGE", "WRITE", "READ"].includes(self.activeTeamPermission),
		},
		{
			name: "Workspace settings",
			href: team_prospect_settings_path,
			current: self.$route.path == team_prospect_settings_path,
			icon: settings_icon,
			divider: true,
			show: ["ADMIN", "MANAGE", "WRITE"].includes(self.activeTeamPermission),
		},
	];
	return navigation;
};

export default {
	mounted() {
		this.navigation = make_navigation(this);
	},

	components: {
		TeamMenuButton,
	},

	data() {
		return {
			navigation: make_navigation(this),
		};
	},

	methods: {},

	watch: {
		$route() {
			this.navigation = make_navigation(this);
		},

		activeTeamId() {
			this.navigation = make_navigation(this);
		},
	},

	computed: {
		...mapGetters(["auth", "activeTeamId", "activeTeamPermission"]),
	},

	name: "MainSidebar",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
