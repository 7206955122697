import axios from "axios";
import store from "@/store";
import env from "@/environment";

// const BASE_URL = 'https://foresightsi-horizon.dev.networkpraxis.com';
// const API_VERSION = 'v1';

const BASE_URL = env.horizonAPIBaseURL;
const API_VERSION = env.horizonAPIVersion;

export default class ApiProvider {
	constructor() {
		this.baseUrl = `${BASE_URL}/${API_VERSION}`;

		this.endpoints = {
			systemPing: "system/ping",
			userRegister: "users/registration",
			users: "users",
			userTeamInvites: "users/{user_id}/team-invites",
			teams: "teams",
			teamMembers: "teams/{team_id}/members",
			teamMemberInvites: "teams/{team_id}/members/invites",
			teamProjects: "teams/{team_id}/projects/{project_type}",

			projectTrash: "teams/{team_id}/projects/{project_type}/{project_id}/trash",
			prospectProjectLists: "teams/{team_id}/projects/prospects/{project_id}/lists",
			prospectProjectProspectList: "teams/{team_id}/projects/prospects/{project_id}/lists/{list_id}",
			prospectProjectSavedSearches: "teams/{team_id}/projects/prospects/{project_id}/saved-searches",

			companyAnalysisProjectCompanyList: "teams/{team_id}/projects/company-analysis/{project_id}/companies",
			companyAnalysisProjectCompany:
				"teams/{team_id}/projects/company-analysis/{project_id}/companies/{company_id}",

			// prospectProjectSavedSearch: 'teams/{team_id}/projects/prospects/{project_id}/saved-searches/{search_id}',
		};

		this.init();
	}

	/**
	 * Get resource's URIs
	 *
	 * @param {string} resource Resource key
	 * @returns {ApiProvider.createEndpoints.ApiProviderAnonym$0}
	 */
	resource(resource, params) {
		return this.createQueries(this.buildUrl(this.endpoints[resource], params));
	}

	/**
	 * Construct Axios queries
	 */
	createQueries(resource) {
		//const resourceURL = `${this.baseUrl}/${resource}`;
		const resourceURL = resource;

		return {
			fetchAll: (query = {}) => this.axios.get(resourceURL, { params: query }),

			fetchOne: (id, query = {}) => this.axios.get(`${resourceURL}/${id}`, { params: query }),

			create: (resource) => this.axios.post(resourceURL, resource),

			create_put: (resource) => this.axios.put(resourceURL, resource),

			put: (payload) => this.axios.put(resourceURL, payload),

			update: (resource) => this.axios.put(`${resourceURL}/${resource.id}`, resource),

			delete: (id, query = {}) => this.axios.delete(`${resourceURL}/${id}`, { params: query }),

			// putResource: ( id, payload) => this.axios.delete(`${resourceURL}/${id}`, payload)
		};
	}

	/**
	 * Initialize Axios defaults and API Map
	 */
	init() {
		this.setupAxios();
	}

	/**
	 * Setup axios
	 */
	setupAxios() {
		this.axios = axios.create({ baseURL: this.baseUrl });

		this.axios.interceptors.request.use(
			(config) => {
				const token = store.getters.auth.token;

				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
				} else {
					throw new axios.Cancel(`Operation canceled by the user \n 
                                            Reson: no auth token present`);
				}

				return config;
			},
			(error) => {
				console.log(`No auth token present @ ApiProvider`);
				console.log(error);
				Promise.reject(error);
			}
		);
	}

	/**
	 * Return a string representation for an actual parameter.
	 *
	 * @param param The actual parameter.
	 * @returns {string} The string representation of <code>param</code>.
	 */
	paramToString(param) {
		if (param == undefined || param == null) {
			return "";
		}
		if (param instanceof Date) {
			return param.toJSON();
		}

		return param.toString();
	}

	/**
	 * Build full resource URL by replacing path parameter place-holders with parameter values
	 * NOTE: query parameters are not handled here.
	 *
	 * @param {string} path The path to append to the base URL.
	 * @param {Object} pathParams The parameter values to append.
	 * @returns {string} The encoded path with parameter values substituted.
	 */
	buildUrl(path, pathParams) {
		if (!path.match(/^\//)) {
			path = "/" + path;
		}

		var url = path;

		url = url.replace(/\{([\w-]+)\}/g, (fullMatch, key) => {
			var value;

			if (Object.prototype.hasOwnProperty.call(pathParams, key)) {
				value = this.paramToString(pathParams[key]);
			} else {
				value = fullMatch;
			}

			return encodeURIComponent(value);
		});

		return url;
	}
}

// export const api = new ApiProvider();
