import Keycloak from "keycloak-js";
import { reactive } from "vue";
import env from "@/environment";
// console.log(env)

//const AUTH_URL = 'http://localhost:8090/auth';
// const AUTH_URL = 'https://keycloak.dev.networkpraxis.com/auth';
const AUTH_URL = env.kcurl;

export default class Auth {
	constructor() {
		this.initOptions = {
			url: AUTH_URL,
			// realm: 'np-foresightsi-horizon',
			// realm: 'np-fsi-dev',
			realm: env.kcrealm,
			clientId: "horizon-webapp",
			onLoad: "login-required",
			checkLoginIframe: false,
		};

		this.auth = null;
		this.keycloak = null;
	}

	/**
	 * Init authentication
	 */
	async init() {
		let keycloak = Keycloak(this.initOptions);
		const { onLoad, checkLoginIframe } = this.initOptions;

		try {
			let auth = await keycloak.init({ onLoad, checkLoginIframe });
			// console.log(auth ? 'Authenticated' : 'Not athenticated');

			if (!auth) {
				window.location.reload();
			} else {
				// console.log(keycloak.idTokenParsed.preferred_username)
				// console.log(keycloak.idTokenParsed.email)
				// console.log(keycloak.idTokenParsed)
				// console.log(keycloak)
				// console.log(keycloak.token)
				// console.log(keycloak.tokenParsed)
				// console.log(`${keycloak.idTokenParsed.given_name.substring(0,1)}  ${keycloak.idTokenParsed.family_name.substring(0,1)}`);
			}

			this.keycloak = keycloak;
			this._setAuth(this.keycloak);
		} catch (e) {
			console.log(`Authentication failed with: ${e}`);
		}
	}

	/**
	 * Logout
	 */
	logout() {
		this._setAuth();
		this.keycloak.logout();
	}

	/**
	 * Shedule token refresh
	 *
	 * @param {Object} keycloak
	 */
	_scheduleTokenRefresh(keycloak) {
		setInterval(
			() => {
				keycloak
					.updateToken(70)
					.then((refreshed) => {
						if (refreshed) {
							console.log("Token refreshed");
							this._setAuth(keycloak);
						} else {
							console.log(
								"Token not refreshed, valid for " +
									Math.round(
										keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000
									) +
									" seconds"
							);
						}
					})
					.catch(() => {
						console.log("Failed to refresh token");
						this._setAuth();
					});
			},
			keycloak,
			60000
		);
	}

	/**
	 * Refresh token
	 *
	 * @param {int} timeout
	 */
	async refreshToken(timeout) {
		try {
			let refreshed = await this.keycloak.updateToken(timeout);

			if (refreshed) {
				console.log("Token refreshed");
				this.auth.token = this.keycloak.token;
			} else {
				console.log(
					"Token not refreshed, valid for " +
						Math.round(
							this.keycloak.tokenParsed.exp + this.keycloak.timeSkew - new Date().getTime() / 1000
						) +
						" seconds"
				);
			}
		} catch (e) {
			console.log(`Failed to refresh token: ${e}`);
		}
	}

	/**
	 * Reset auth object
	 *
	 * @param {Object} keycloak
	 */
	_setAuth(keycloak = null) {
		let auth = null;
		if (keycloak) {
			const token = keycloak.token;
			const user = {
				firstName: keycloak.idTokenParsed.given_name,
				lastName: keycloak.idTokenParsed.family_name,
				get initials() {
					return `${this.firstName.substring(0, 1)} ${this.lastName.substring(0, 1)}`;
				},
				email: keycloak.idTokenParsed.email,
			};

			let fsipRoles = [];
			if ("horizon-webapp" in keycloak.tokenParsed.resource_access) {
				fsipRoles = keycloak.tokenParsed.resource_access["horizon-webapp"].roles;
			}
			const roles = {
				fsipRoles: fsipRoles,
			};

			auth = {
				token,
				user,
				roles,
			};
		}

		this.auth = auth;
	}
}
