<!-- This example requires Tailwind CSS v2.0+ -->
<template>
	<Disclosure as="nav" class="relative bg-gray-800 sticky top-0 z-50 shadow-md" v-slot="{ open }">
		<div class="mx-auto px-2 sm:px-6 lg:px-8">
			<div class="relative flex items-center justify-between h-16">
				<div class="absolute inset-y-0 left-0 flex items-center md:hidden">
					<!-- Mobile menu button-->
					<DisclosureButton
						class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
						<span class="sr-only">Open main menu</span>
						<MenuIcon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
						<XIcon v-else class="block h-6 w-6" aria-hidden="true" />
					</DisclosureButton>
				</div>
				<div class="flex-1 flex items-center justify-center md:items-stretch md:justify-start">
					<div class="flex-shrink-0 flex items-center">
						<router-link to="/user/home">
							<!-- <img class="block lg:hidden h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg" alt="Foresight SI Horizon" />
							<img class="hidden lg:block h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg" alt="Foresight SI Horizon" /> -->
							<div
								class="flex items-center text-white px-2 hover:text-gray-800 hover:bg-white rounded transition duration-300 ease-in-out">
								<div class="font-light mr-2 text-2xl">//</div>
								<div class="mr-2 font-bold">Foresight SI</div>
							</div>
						</router-link>
						<div v-if="devMode" class="ml-3 text-white text-sm font-light">Development Mode</div>
					</div>
					<div class="hidden">
						<div class="flex space-x-4">
							<router-link
								v-for="item in navigation"
								:key="item.name"
								:to="item.href"
								:class="[
									item.current
										? 'bg-gray-900 text-white'
										: 'text-gray-100 hover:bg-gray-700 hover:text-white',
									'px-3 py-2 rounded-md text-sm font-medium',
								]">
								{{ item.name }}
							</router-link>
						</div>
					</div>
				</div>
				<div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
					<!-- Profile dropdown -->
					<Menu as="div" class="ml-3 relative">
						<div>
							<MenuButton
								class="bg-gray-800 flex items-center text-sm rounded-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
								v-slot="{ open }">
								<!-- <MenuButton class="bg-gray-800 flex items-center text-sm rounded-full"> -->
								<span class="sr-only">Open user menu</span>
								<button
									class="h-11 w-11 text-gray-700 text-center font-bold flex items-center justify-center bg-white rounded-full hover:bg-gray-100 focus:bg-gray-300">
									<span class="text-lg font-black">{{ initials }}</span>
									<!-- <span v-if="open">
										<svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
										</svg>
									</span>
									<span v-else>
										<svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
											<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
										</svg>
									</span> -->
								</button>
								<span v-if="open" class="ml-3 text-white">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										class="h-3 w-3"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor">
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M5 15l7-7 7 7" />
									</svg>
								</span>
								<span v-else class="ml-3 text-white">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										class="h-3 w-3"
										fill="none"
										viewBox="0 0 24 24"
										stroke="currentColor">
										<path
											stroke-linecap="round"
											stroke-linejoin="round"
											stroke-width="2"
											d="M19 9l-7 7-7-7" />
									</svg>
								</span>
							</MenuButton>
						</div>
						<transition
							enter-active-class="transition ease-out duration-100"
							enter-from-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-from-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95">
							<MenuItems
								class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
								<!-- <MenuItem>
									<div class="block px-4 py-2 text-xs text-gray-700 text-center bg-red-200 rounded-full m-2 ml-4 mr-4">
										<div class="italic underline">Team:</div>
										<div class="font-semibold">AD's personal</div>
									</div>
								</MenuItem> -->

								<!-- <MenuItem v-slot="{ active }">
									<a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Switch team</a>
								</MenuItem> -->
								<MenuItem v-slot="{ active }" class="rounded-t-md">
									<a
										class="cursor-pointer"
										:class="[
											active ? 'bg-gray-100' : '',
											'block px-4 py-2 text-sm text-gray-700 font-bold flex justify-between w-full',
										]"
										v-on:click="gotoprofile">
										Home
										<ChevronRightIcon class="ml-2 h-5 w-5" aria-hidden="true" />
									</a>
								</MenuItem>
								<MenuItem v-slot="{ active }" class="mt-2 border-t-2 rounded-b-md">
									<a
										class="cursor-pointer"
										:class="[
											active ? 'bg-gray-100' : '',
											'text-gray-700',
											'block px-4 py-2 text-sm text-red-700 font-bold flex justify-between w-full',
										]"
										v-on:click="logout">
										Sign out
										<ChevronRightIcon class="ml-2 h-5 w-5" aria-hidden="true" />
									</a>
								</MenuItem>
								<div class="mt-2 border-t">
									<div class="mr-2 rounded-b-md text-xs font-light text-right">
										Build (#) {{ buildnum }}
									</div>
								</div>
							</MenuItems>
						</transition>
					</Menu>
				</div>
			</div>
		</div>

		<DisclosurePanel class="md:hidden">
			<div class="px-2 pt-2 pb-3 space-y-1">
				<router-link
					v-for="item in navigation"
					:key="item.name"
					:to="item.href"
					:class="[
						item.current ? 'bg-gray-900 text-white' : 'text-gray-100 hover:bg-gray-700 hover:text-white',
						'block px-3 py-2 rounded-md text-base font-medium',
					]">
					{{ item.name }}
				</router-link>
			</div>
		</DisclosurePanel>
	</Disclosure>
</template>

<script>
import { ref } from "vue";
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
// import { BellIcon, MenuIcon, XIcon } from '@heroicons/vue/outline'
import { MenuIcon, XIcon } from "@heroicons/vue/outline";
import { mapGetters } from "vuex";
import { inject } from "vue";
import { ChevronRightIcon } from "@heroicons/vue/solid";
import env from "@/environment";
//import { auth } from '@/services/Auth';

let make_navigation = function (self) {
	let navigation = [];

	if (self.$route.path.includes("/workspace/")) {
		let team_dashboard_path = "/workspace/" + self.activeTeamId + "/dashboard";
		let team_projects_path = "/workspace/" + self.activeTeamId + "/projects";
		let team_prospect_search_path = "/workspace/" + self.activeTeamId + "/prospect-search";
		let team_domain_analysis_path = "/workspace/" + self.activeTeamId + "/domain-analysis";
		let team_digital_reference_check_path = "/workspace/" + self.activeTeamId + "/digital-reference-check";
		let team_prospect_settings_path = "/workspace/" + self.activeTeamId + "/settings";
		navigation = [
			{
				name: "Workspace dashboard",
				href: team_dashboard_path,
				current: self.$route.path == team_dashboard_path,
			},
			{
				name: "Projects",
				href: team_projects_path,
				current: self.$route.path == team_projects_path,
			},
			{
				name: "Company search",
				href: team_prospect_search_path,
				current: self.$route.path == team_prospect_search_path,
			},
			{
				name: "Quick Company analysis",
				href: team_domain_analysis_path,
				current: self.$route.path == team_domain_analysis_path,
			},
			{
				name: "Digital Reference Check",
				href: team_digital_reference_check_path,
				current: self.$route.path == team_digital_reference_check_path,
			},
			{
				name: "Workspace settings",
				href: team_prospect_settings_path,
				current: self.$route.path == team_prospect_settings_path,
			},
		];
	} else {
		navigation = [
			{
				name: "Home",
				href: "/user/home",
				current: self.$route.path == "/user/home",
			},
			{
				name: "Workspaces",
				href: "/user/workspaces",
				current: self.$route.path == "/user/workspaces",
			},
		];
	}
	return navigation;
};

export default {
	mounted() {
		// this.initials = this.auth.user.initials;
		if (this.activeUser) {
			this.initials = this.activeUser.name.substring(0, 1);
		}
		this.navigation = make_navigation(this);
	},

	data() {
		return {
			devMode: process.env.NODE_ENV == "development",
			buildnum: env.buildnum,
			initials: "",
			navigation: make_navigation(this),
		};
	},

	methods: {
		gotoprofile() {
			this.$router.push({
				name: "user-home",
			});
		},
		logout() {
			this.$store.dispatch("logout");
		},
	},

	watch: {
		$route() {
			this.navigation = make_navigation(this);
		},

		activeUser() {
			this.initials = this.activeUser.name.substring(0, 1);
		},

		activeTeamId() {
			this.navigation = make_navigation(this);
		},
	},

	computed: {
		...mapGetters(["auth", "activeUser", "activeTeamId"]),
	},

	components: {
		Disclosure,
		DisclosureButton,
		DisclosurePanel,
		Menu,
		MenuButton,
		MenuItem,
		MenuItems,
		// BellIcon,
		MenuIcon,
		XIcon,
		ChevronRightIcon,
	},
	setup() {
		// const open = ref(false);
		// //const auth = inject('auth');
		// // console.log(auth);
		// return {
		// 	open,
		// 	//auth
		// }
	},
};
</script>
