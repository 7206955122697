<template>
	<!-- Filler div -->
	<div
		class="sidebar text-gray-600 md:w-1/4 lg:w-1/5 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out"></div>
	<!-- Fixed nav div -->
	<div
		class="md:w-1/4 lg:w-1/5 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform -translate-x-full transition duration-200 ease-in-out md:fixed md:translate-x-0 border-r">
		<nav class="mt-14">
			<router-link
				v-for="item in navigation"
				:key="item.name"
				:to="item.href"
				:class="[
					item.current ? 'bg-gray-500 text-white' : '',
					'block py-2.5 px-4 rounded-md transition duration-200 hover:bg-gray-700 hover:text-white my-2',
				]"
				:aria-current="item.current ? 'page' : undefined">
				<div class="flex items-center">
					<span v-html="item.icon"></span>
					<div class="ml-2">{{ item.name }}</div>
				</div>
			</router-link>
		</nav>
	</div>
</template>

<script>
let home_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
						<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
					</svg>`;
let teams_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
					  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
					</svg>`;

import { mapGetters } from "vuex";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";

let make_navigation = function (self) {
	const navigation = [
		// { name: 'Home', href: '/user/home', current: self.$route.path == '/user/home', icon: home_icon },
		{
			name: "Workspaces",
			href: "/user/workspaces",
			current: self.$route.path == "/user/workspaces",
			icon: teams_icon,
		},
		// { name: 'Settings', href: '/', current: self.$route.path.includes('/settings'), icon: projects_icon },
	];
	return navigation;
};

export default {
	created() {
		this.user_id = this.auth.user.email;
	},

	mounted() {},

	components: {},

	data() {
		return {
			user_id: null,
			navigation: make_navigation(this),
		};
	},

	methods: {},

	watch: {
		$data() {},
		user_id() {
			this.user_id = this.auth.user.email;
			this.navigation = make_navigation(this);
		},
		$route() {
			this.navigation = make_navigation(this);
		},
	},

	computed: {
		...mapGetters(["auth"]),
	},

	name: "UserProfileSidebar",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
