import { createApp } from "vue";
import router from "@/router";
import store from "@/store";
import App from "./App.vue";
import Auth from "@/services/Auth";
import Api from "@/services/ApiProvider";
import "./assets/sass/app.scss";
import { reactive } from "vue";
import "./assets/tailwind.css";

(async () => {
	await store.dispatch("authenticate");

	const app = createApp(App);
	app.use(router);
	app.use(store);
	app.mount("#app");
	app.config.globalProperties.$api = new Api();
})();
