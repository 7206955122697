import buildnum from "@/version";

const production = {
	horizonAPIBaseURL: "https://horizon.api.foresightsi.com",
	horizonAPIVersion: "v1",
	horizonSearchAPIBaseURL: "https://horizon-search.api.foresightsi.com",
	horizonSearchAPIVersion: "v1",
	// horizonProspectSearchURL: 'https://foresightsi-search.dev.networkpraxis.com',
	// horizonProspectSearchKey: 'bpiikWUlZVt0j49ljj1aUiBocbjXAVe4',
	fsiProfessorAPIBaseURL: "https://professor.fsi.api.foresightsi.com",
	fsiProfessorAPIVersion: "v1",
	fsiSchedulerAPIBaseURL: "https://scheduler.fsi.api.foresightsi.com",
	fsiSchedulerAPIVersion: "v1",
	// kcurl: 'https://keycloak.dev.networkpraxis.com/auth',
	kcurl: "https://auth.foresightsi.com/",
	kcrealm: "np-foresightsi-horizon",
};

const development = {
	horizonAPIBaseURL: "https://foresightsi-horizon.dev.networkpraxis.com",
	horizonAPIVersion: "v1",
	horizonSearchAPIBaseURL: "https://foresightsi-horizon-search.dev.networkpraxis.com",
	horizonSearchAPIVersion: "v1",
	// horizonProspectSearchURL: 'https://foresightsi-search.dev.networkpraxis.com',
	// horizonProspectSearchKey: 'bpiikWUlZVt0j49ljj1aUiBocbjXAVe4',
	fsiProfessorAPIBaseURL: "https://professor.fsi.api.dev.networkpraxis.com",
	fsiProfessorAPIVersion: "v1",
	fsiSchedulerAPIBaseURL: "https://scheduler.fsi.api.dev.networkpraxis.com",
	fsiSchedulerAPIVersion: "v1",
	kcurl: "https://keycloak.dev.networkpraxis.com/auth",
	kcrealm: "np-fsi-dev",
};

let env = null;
if (process.env.NODE_ENV == "production") {
	env = production;
} else if (process.env.NODE_ENV == "development") {
	env = development;
} else {
	throw "Unknown NODE_ENV";
}

env.buildnum = buildnum;

export default env;
